@import "../../../themes/palette.scss";
@import "../../../themes/colors.scss";

.operationCropBox {
	> :first-child {
		margin-bottom: 10px;
	}

	.row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.height {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex: 1;
			justify-content: space-between;
			margin-right: 30px;

			.text {
				color: $text-main-color;
				font-size: 12px;
				font-stretch: normal;
				font-style: normal;
				font-weight: normal;
				letter-spacing: normal;
				line-height: normal;
				margin-right: 10px;
				text-align: right;
			}
		}

		.width {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex: 1;
			justify-content: space-between;

			.text {
				color: $text-main-color;
				font-size: 12px;
				font-stretch: normal;
				font-style: normal;
				font-weight: normal;
				letter-spacing: normal;
				line-height: normal;
				margin-right: 10px;
				text-align: right;
			}
		}
	}
}
