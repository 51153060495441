@import '../../themes/palette.scss';
@import "../../index.scss";

.customQuery {
	.leftContainer {
		padding: 21px 14px 21px 30px;
		height: calc(100% - 42px);
		display: flex;

		.querySave {
			width: 30%;
			height: auto;
			margin-right: 16px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			box-shadow: 0 1px 6px 0 $app-bar-shadow;

			.addButton{
				cursor: pointer;
			}

			.saveHead {
				align-items: center;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				background: $auth-button-background;
				display: flex;
				font-family: Roboto;
				font-weight: bold;
				height: 50px;
				justify-content: space-between;
				padding: 0 10px;
			}
		}

		.query {
			display: flex;
			flex-direction: column;
			width: 100%;

			.tabs {
				box-shadow: 0 1px 6px 0 $app-bar-shadow;
				display: flex;
				flex: 1 1 auto;
				height: 100%;

				.queryButton {
					display: flex;
					justify-content: space-between;
					gap: 13px;
					width: 120px;
				}
			}

			.errorMessage {
				margin-top: 10px;
			}

		}
	}

	.rightContainer {
		height: 100%;
		padding: 0 25px 0 9px;
	}
}
