@import "../themes/colors.scss";
@import "../themes/palette.scss";

.update-password-content {
	width: 400px;
	padding-top: 15px;
	padding-bottom: 25px;

	.update-password-input {
		padding-bottom: 5px;
		
		.update-password-button {
			padding: 7px;
		}
	}

	.update-password-error {
		color: $status-error-color;
		font-family: Roboto !important;
		font-size: 12px !important;
		font-weight: 500 !important;
		letter-spacing: 0.11px !important;
		line-height: 24px !important;
	}

	.update-password-success {
		color: $status-success-color;
		font-family: Roboto !important;
		font-size: 12px !important;
		font-weight: 500 !important;
		letter-spacing: 0.11px !important;
		line-height: 24px !important;
	}
}
