@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.tab-Panel {
	background-color: $white;
	flex-direction: column;
	height: 100%;
	display: none !important;

	&.react-tabs__tab-panel--selected {
		display: flex !important;
	}
}