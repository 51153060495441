@import "../themes/colors.scss";
@import "../themes/palette.scss";

.edit-user-content {
	width: 400px;
	padding-top: 15px;
  padding-bottom: 25px;

  .edit-user-input {
    padding-bottom: 5px;

    .edit-user-button {
      padding: 7px;
    }

    .edit-user_select-title {
      margin-top: 7px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 24px;
      color: $text-main-color;
    }
  }

	.edit-user_token {
	
		.edit-user-token-input {
			padding-bottom: 5px;
			display: flex;
			
			.edit-user-token-textfield {
				flex-grow: 1;
			}
	
			.edit-user-token-button {
				margin-top: 20px;
				padding: 7px;
			}
		}
	} 
}
