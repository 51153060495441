@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.videoSearch {
	.leftContainer {
		margin: 0 14px 21px 30px;

		.runButton {
			background-color: $background-color;
			padding-bottom: 10px;
			padding-top: 21px;
			position: sticky;
			top: 0;
			z-index: 10;
		}

		.errorMessage {
			margin-bottom: 10px;
		}

		.filter {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
		}

		.operations {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
			margin-top: 10px;
		}

		.box {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
			margin-top: 10px;
		}
	}

	.rightContainer {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;

		.video {
			flex: 1;
			overflow: auto;
			padding: 0 20px;
		}

		.queries {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
			margin: 21px 30px 21px 14px;
		}
	}
}
