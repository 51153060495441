@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.helpTextMessage {
	margin: 0 5px;

	&.helpTextIcon {
		color: $help-text-color;
		font-size: 5px !important;
	}
}