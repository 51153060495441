@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.status {
	.leftContainer {
		margin: 21px 14px 21px 30px;

		.system {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
		}

		.table {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
			margin-top: 21px;
		}
	}

	.rightContainer {
		margin: 21px 30px 21px 14px;

		.chart {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
		}

		.list {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;
			margin-top: 21px;
		}
	}
}
