@import "../../../themes/palette.scss";
@import "../../../themes/colors.scss";

.propertyBoolean {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	.label {
		color: $text-main-color;
		font-size: 12px;
		font-stretch: normal;
		font-style: normal;
		font-weight: normal;
		letter-spacing: normal;
		line-height: normal;
	}
}
