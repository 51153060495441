@import "../../themes/palette.scss";
@import "../../themes/colors.scss";

.menuDivider {
	width: 100%;
	height: 1px;
	margin-left: 0 !important;
	background-color: $divider-color;
}

.list {
	border-radius: 3px !important;
	background-color: $white !important;
	box-shadow: 0 2px 6px 0 rgba(195, 195, 195, 0.5) !important;
}

.menuOptions {
	text-decoration: none !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 12px;
	padding: 2px 8px 2px 2px;
	margin-right: 15px;
	width: 100%;
}
