.manage-token-content {
	width: 400px;
	padding-top: 15px;
	padding-bottom: 25px;

	.manage-token-input {
		padding-bottom: 5px;
		display: flex;
		
		.manage-token-textfield {
			flex-grow: 1;
		}

		.manage-token-button {
			margin-top: 20px;
			padding: 7px;
		}
	}
}
