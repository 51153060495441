@import "../../themes/palette.scss";
@import "../../index.scss";

.similaritySearch {
	flex: 1;
	visibility: "visible";

	.container {
		.top {
			margin-top: 20px;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			height: 80%;

			.actions {
				flex: 1;
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.searchContainer {
					margin-left: 30px;
					width: 80%;
				}

				.descriptorSelectContainer {
					margin-left: 10px;
					margin-right: 10px;
					width: 200px;
				}

				.searchButtonContainer {
					margin-right: 30px;
					width: 150px;
				}
			}
		}

		.contentView {
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;

			.imagesContainer {
				flex: 1;
				overflow: auto;
				padding: 0 20px;
			}

			.notFound {
				display: grid;
				place-items: center;
				height: 100%;
				text-align: center;
			}
		}
	}
}

.similaritySearchHidden {
	display: none;
	visibility: "hidden";
}
