@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.tabContainer {
	border-radius: 6px;
	box-shadow: 0 0 6px 0 $app-bar-shadow !important;
	background-color: transparent !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.tabItem {
	background-color: darken($background-color, 5%);
	border: 1px solid transparent !important;
	border-radius: 6px 6px 0 0 !important;
	color: $tab-selected;
	flex: auto;
	line-height: normal;
	letter-spacing: 0.55px;
	bottom: 0px !important;

	&:focus {
		box-shadow: 0 0 6px transparent !important;
		border-color: transparent !important;
	}

	&:after {
		content: '';
		position: absolute;
		height: 0px;
		left: -4px;
		right: -4px;
		bottom: -5px;
		background: transparent !important;
	}
}

.tabPanelContainer {
	background-color: $white;
	padding: 20px 15px 30px 20px;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.tabPanelItem {
	background-color: $white;
	border-radius: 4px;
	border: solid 1px $property-box-divider;
	padding: 5px 0px;
	display: flex;
	height: 100%;
}

.tabList {
	display: flex;
	background-color: transparent !important;
	padding: 0 0 10px;
	margin: 0px !important;
	border-bottom: 1px solid $white !important;
}

.tabSubItem {
	display: flex;
	justify-content: space-between;
	font-family: Roboto !important;
	font-size: 22px !important;
	font-stretch: normal !important;
	font-style: normal !important;
	margin-top: 5px !important;
	margin-left: 10px !important;
	margin-bottom: 0px !important;
}

.tabSelected {
	background-color: $white !important;
	color: $text-main-color !important;
	flex: auto;
	line-height: normal;
	font-weight: bold !important;
	letter-spacing: 0.55px;
}
