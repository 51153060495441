@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.sectionTitle {
	font-size: 22px !important;
	font-weight: bold !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: normal !important;
	letter-spacing: 0.55px !important;
	color: $text-main-color !important;
}
