@import "../themes/colors.scss";
@import "../themes/palette.scss";

.new-user-content {
  width: 400px;
  padding-top: 15px;
  padding-bottom: 25px;

  .new-user-input {
    padding-bottom: 5px;

    .new-user-button {
      padding: 7px;
    }

    .new-user_select-title {
      margin-top: 7px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 24px;
      color: $text-main-color;
    }
  }
}
