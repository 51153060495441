@use '../../themes/colors.scss';
@import '../../index.scss';

.asPagination {
	display: flex;
	flex-direction: row;
	align-items: center;

	.textPaginationDisplaying {
		@extend .text-regular;		
			font-size: 10px;
			margin-right: 5px;
		}
	
		.textPaginationOfTotal {
			@extend .text-regular;	
			font-size: 14px;
		}	
}
