@import '../../themes/palette.scss';
@import "../../index.scss";

.login {
	.container {
		position: absolute;
		border-radius: 3px;
		background-color: $white;
		box-shadow: 0 2px 6px 0 rgba(195,195,195,0.5);
		padding: 30px 32px;
		width: 394px;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;

		.title {
			color: $text-main-color;
			font-family: Roboto;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 28px;
			margin:27px 0 !important;
		}

		.text {
			margin-top: 3px;
			margin-bottom: 15px;
		}
	
		.button {
			margin-top: 15px;
		}

		.ipPort {
			display: flex;
			margin: 10;
			height: 30px !important;
	
			.button {
				color: $auth-button-color !important;
				margin: 0 !important;
			}
	
			.title {
				color: $auth-button-color;
				font-family: Roboto;
				font-size: 14px !important;
				letter-spacing: 0.13px;
				line-height: 16px;
				margin-top: 7px !important;
				margin-left: 5px !important;
			}
	
			.divider {
				margin-top: 13px;
				box-sizing: border-box;
				height: 2px;
				border: 1px solid $auth-button-color;
				flex: 1;
			}
		}
	}
}
