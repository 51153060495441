@import "../themes/colors.scss";
@import "../themes/palette.scss";

.new-role-content {
  width: 500px;
  padding-top: 15px;
  padding-bottom: 25px;

  .access-control-interior-table {
    .access-control-table__row {
      background-color: $background-color;
    }

    .access-control-inside-table__header {
      background-color: white;

      .access-control-inside-table__text-header {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        color: $text-main-color;

        &:first-child {
          padding-left: 24px;
        }
      }
    }

    .access-control-inside-table__entity-name {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 12px;
      letter-spacing: 0;
      color: $text-main-color;
      margin-left: 10px;
    }

    .access-control-inside-table__text-body {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 12px;
      letter-spacing: normal;
      color: $links-main-color;

      label {
        margin: 0;
      }

      &.show-borders {
        border-right: solid 1px $divider-color;
        border-left: solid 1px $divider-color;
      }
    }

    .MuiTableCell-root {
      border-color: $divider-color;
      border-bottom-width: 0;
    }
  }

  .new-role-input {
    padding-bottom: 5px;

    &:first-child {
      margin-bottom: 25px;
    }

    .new-role-button {
      padding: 7px;
    }

    .new-role_select-title {
      margin-top: 7px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.11px;
      line-height: 24px;
      color: $text-main-color;
    }
  }
}
