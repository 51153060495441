@import '../../themes/palette.scss';
@import '../../themes/colors.scss';

.controlAccessDialog {
	.controlAccessContent {
		border-radius: 3px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 6px 0 rgba(195, 195, 195, 0.5);

		.controlAccessDivider {
			width: 100%;
			height: 1px;
			margin-left: 0 !important;
			background-color: $divider-color;
		}
	}

	.controlAccessTitle {
		background-color: $tab-selected;
		color: $background-color;
		font-family: Roboto;
		font-size: 24px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 28px;
	}

	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}

	.confirmButtons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 100%;
	}

	.editUserDeleteButton {
		.deleteIcon {
			color: red;
		}
	}
}
