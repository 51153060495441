@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.platformLabel {
  border-radius: 25px;
  margin-left: 5px;
  padding: 0 6px;
  max-width: 160px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 11px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }



  &.warning {
    background-color: $status-warning-background;
    color: $status-warning-color;
  }

  &.error {
    background-color: $status-error-background;
    color: $status-error-color;
  }

  &.success {
    background-color: $status-success-background;
    color: $status-success-color;
  }

  &.success-row {
    background-color: $status-success-row-background;
    color: $status-success-row-text;
  }

  &.stopped {
    background-color: $status-stopped-background;
    color: $status-stopped-color;
  }

  &.stopped-row {
    background-color: $status-stopped-background;
    color: $text-main-color;
  }


  .dot {
    width: 6px;
    min-width: 6px;
    height: 6px;
    min-height: 6px;
    border-radius: 3px;
    margin-right: 4px;


    &.warning {
      background-color: $status-warning-color;
    }

    &.error {
      background-color: $status-error-color;
    }

    &.success {
      background-color: $status-success-color;
    }

    &.success-row {
      background-color: $status-success-row-color;
    }

    &.stopped {
      background-color: $status-stopped-color;
    }

    &.stopped-row {
      background-color: $status-stopped-color;
    }
  }
}