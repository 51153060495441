@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.statusChart {
	box-shadow: 0 1px 6px 0 $app-bar-shadow;

	.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px;
	}

	.pie {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;

		.textHeader {
			color: $text-main-color;
			font-size: 10px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: 0.5px;
			line-height: normal;

			&:first-child {
				padding-left: 24px;
			}
		}
	}
}
