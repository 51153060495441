@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.platformSelectBoxControl {
	width: 100%;
	background-color: $white;
	height: 30px;

	.platformSelectBox {
		height: 30px;

		input {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			padding: 8px 10px;
			color: $text-main-color;
		}

		fieldset {
			border-color: $property-box-divider !important;
		}
	}
}
