@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.platformCheckBoxControlLabel {
	span {
		font-size: 0.7vw;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}
}
