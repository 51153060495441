@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.statusSystem {
	box-shadow: 0 1px 6px 0 $app-bar-shadow;

	.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px 20px 10px 20px;

		.arrowButton {
			color: $links-main-color;
		}
	}

	.graphic {
		display: flex;
		justify-content: space-between;
	}

	.pieContainer {
		color: $text-main-color;
		display: flex;
		font-size: 10px;
		font-stretch: normal;
		font-style: normal;
		font-weight: normal;
		justify-content: space-between;
		letter-spacing: 0.5px;
		line-height: normal;
		padding-bottom: 20px;
		padding-left: 20px;
		padding-right: 10px;

		.status {
			display: flex;
			justify-content: space-between;
		}
	}
}
