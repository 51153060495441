$text-main-color: #333344;
$text-secondary-color: #71717d;
$links-main-color: #4648c6;
$background-color: #f8f8fc;
$help-text-color: #dbdbe2;

$divider-color: #ececf9;
$app-bar-shadow: #e4e5ee;

$property-box-divider: #ececfa;

$modal-properties-title: #e5e5f6;

$tab-selected: #4648c6;

$status-error-background: #ffbcc4;
$status-error-color: #c62e2e;

$status-success-background: #d7f8d2;
$status-success-color: #1aa71a;

$status-success-row-background: #C8F9BE;
$status-success-row-color: #57C42D;
$status-success-row-text: #429A20;

$status-warning-background: #fcffac;
$status-warning-color: #ccc920;

$status-stopped-color: #BCBCBC;
$status-stopped-background: #ECECEC;

$top-bar-user-color: #212121;

$auth-button-background: #E6E6F4;
$auth-button-color: #4548C6;

$scroll-background: #D0D4D5;
$scroll-hover-background:#c3c3c3;
