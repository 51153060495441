@import "../../../themes/palette.scss";
@import "../../../themes/colors.scss";

.operationFlipBox {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.button {
		border-radius: 5px;
		border: solid 2px $property-box-divider;
		color: $links-main-color;
		display: flex;

		&:hover {
			cursor: pointer;
		}

		&.selected {
			border-color: $links-main-color;
		}
	}
}
