@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.statusList {
	box-shadow: 0 1px 6px 0 $app-bar-shadow;

	.header {
		background-color: darken($background-color, 5%);

		.text {
			font-size: 10px;
			color: $text-main-color;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: 0.5px;
			line-height: normal;
			padding-right: 16px;
		}
	}

	.top {
		padding: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.tile {
			color: $text-main-color;
			font-size: 22px;
			font-stretch: normal;
			font-style: normal;
			font-weight: bold;
			letter-spacing: 0.55px;
			line-height: normal;
		}
	}

	.row {
		.text {
			color: $text-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: normal;
		}

		.bar {
			border-radius: 4px;
			height: 8px;
		}
	}

	.textHeader {
		color: $text-main-color;
		font-size: 10px;
		font-stretch: normal;
		font-style: normal;
		font-weight: normal;
		letter-spacing: 0.5px;
		line-height: normal;

		&:first-child {
			padding-left: 24px;
		}
	}
}
