@use "../../themes/colors.scss";
@import "../../index.scss";

.contentGallery {
	background-color: $background-color;
	min-height: 100%;
	margin: 5px;
	margin: 0;
	min-width: 100%;
	display: flex;
	flex-direction: column;

	.headerGallery {
		margin-left: 5px;
		margin-right: 5px;
		display: flex;
		justify-content: space-between;

		.titleGallery {
			@extend .text-base;
		}
	}

	.thumbnailContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.contentGalleryProgressLoading {
	@extend .contentGallery;
	align-items: center;
	justify-content: center;
}
