@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.modalBody {
	background: white;
	width: 400px;
	height: 170px;
	margin: 0 auto;
	margin-top: 10%;
	border-radius: 6px;

	.headModal {
		border-radius: 6px;
		display: flex;
		justify-content: flex-end;
	}

	.textContainer {
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 0px;
		margin-top: 0px;
		font-size: 14px;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;

		p {
			margin: 0 auto;
			margin-bottom: 15px;
		}
	}


	.btnContainer{
		display: flex;
		justify-content: space-between;
		padding-left: 20px;
		padding-right: 20px;

		div {
			width: 140px;
		}
	}
}
