@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.modalBody {
	background: white;
	width: 350px;
	height: 170px;
	margin: 0 auto;
	margin-top: 10%;
	border-radius: 6px;

	.headModal {
		border-radius: 6px;
		display: flex;
		justify-content: space-between;

		.title {
			width: 100%;
			text-align: center;

			p {
				margin-left: 40px;
			}
		}
	}

	.inputContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.resultBoxTop {
			display: flex;
			flex-direction: row;
			align-items: center;

		}

		.resultBoxLimit {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.resultBoxLimitText {
				flex: 2;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.errorTextModal{
					color: red;
					font-size: 15px;
				}
			}
		}
	}

	.btnContainer{
		width: 180px;
		margin: 0 auto;
		margin-top: 20px;
	}
}
