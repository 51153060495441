@use '../../themes/colors.scss';
@import '../../index.scss';

.platformGraphic {
	padding: 10px;

	.platformGraphicProgress {
		padding: 20px;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		text-align: center;

		.platformGraphicProgressCircle {
			width: 70%;
			margin: 0 auto;
			font-weight: 100;

			.platformGraphicProgressData {
				font-size: clamp(16px, 1.67vw, 24px);
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
			}

			.platformGraphicProgressTitle {
				color: darken($background-color, 15%);
				font-size: clamp(10px, 0.97vw, 14px);
			}
		}

		.platformGraphicTitle {
			text-align: center;
			font-size: 10px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.5px;
			color: $text-main-color;
			padding-top: 10px;
		}
	}

	.platformProgressInfo {
		background-color: darken($background-color, 5%);
		width: 100%;
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.5px;
		color: $text-main-color;
		padding: 20px 0;
		text-align: center;
		border-radius: 3%;

		.platformProgressInfoBoxTitle {
			font-size: 10px;
		}
	}
}