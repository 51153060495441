@use "../../themes/colors.scss";
@import "../../index.scss";

.propertiesContainerLeft {
	flex: 1;
	overflow: auto;
}

.titleLeft {
	@extend .text-column-title;
	background-color: $modal-properties-title;
	mix-blend-mode: multiply;
	padding: 2px 0 2px 10px;
	margin-top: 0;
	box-shadow: 0 -1px 0 0 $divider-color;
	height: 1vw;
}

.property {
	@extend .text-regular;

	.propertyName {
		@extend .text-regular;
		display: inline-block;
		word-wrap: break-word;
		width: 5vw;
		font-weight: bold;
		position: relative;
		vertical-align: top;
	}

	.propertyValue {
		@extend .text-regular;
		display: inline-block;
		word-wrap: break-word;
		width: 60%;
		margin-left: 10%;
	}
}
