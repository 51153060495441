@use "../../themes/colors.scss";
@import "../../index.scss";

.videoModal {
	position: absolute;
	top: 13vh;
	left: 13vw;
	width: 75vw;
	height: 35vw;
	background-color: $background-color;
	border-radius: 6px;
	padding: 2vw 2vw;

	.closeIcon {
		position: absolute !important;
		right: 0;
		top: 0;

		.svgIcon {
			color: $links-main-color;
			width: 1.5vw;
			height: 1.5vw;
		}
	}

	.titleModal {
		height: 1.5vw;
		margin: calc(1vw / 2) 0;
	}

	.contentModal {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		height: 30vw;

		.contentLeft {
			flex: 1;
			display: flex;
			flex-direction: column;
			margin-right: 20px;
		}

		.contentRight {
			flex: 3;
			display: flex;
			align-items: center;
			justify-content: center;

			.content {
				width: 100%;
				max-height: 100%;
			}
		}
	}

	.bottomNavigation {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 1vw;

		.btnText {
			color: $links-main-color;
			text-transform: capitalize;
			font-size: 0.9vw;
		}
	}
}
