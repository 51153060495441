@use "../../themes/colors.scss";
@import "../../index.scss";

.imageBoundingBoxPropertiesEdition {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-right: 20px;

	.titleLeft {
		@extend .text-column-title;
		background-color: $modal-properties-title;
		mix-blend-mode: multiply;
		padding: 2px 0 2px 10px;
		margin-top: 0;
		box-shadow: 0 -1px 0 0 $divider-color;
		height: 1vw;
	}

	.propertiesContainerLeft {
		flex: 1;
		overflow: auto;

		.propertyField {
			@extend .text-regular;
			display: flex;
			align-items: baseline;
			justify-content: space-evenly;

			.propertyName {
				@extend .text-regular;
				display: inline-block;
				word-wrap: break-word;
				width: 5vw;
				font-weight: bold;
				position: relative;
				vertical-align: top;
			}

			.inputField {
				input::-webkit-inner-spin-button,
				input::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}
	}

	.containerButton {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.groupButton {
			margin-top: 10px;
			display: flex;
			justify-content: end;
			align-items: center;
		}
	}
}
