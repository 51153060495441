@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.userTable {
	.headerContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 60px;

		.title {
			width: 100px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 66%;

			.search {
				flex: 1;
			}

			.ddUserButton {
				width: 150px;
				margin-left: 20px;
			}
		}
	}

	.tableContainer {
		box-shadow: 0 1px 6px 0 $app-bar-shadow;

		.table {
			box-shadow: 0 1px 6px 0 $app-bar-shadow;

			.tableHeader {
				background-color: darken($background-color, 5%);
			
				.text {
					font-size: 10px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: 0.5px;
					color: $text-main-color;
				}
			}

			.body {
				.header {
					background-color: white;
					
					.headerText {
						font-size: 12px;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: 0;
						color: $text-main-color;
						border-bottom-width: 0;
					}
				}
				

				.rowWithColor {
					background-color: $background-color;

					.entityName {
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 12px;
						letter-spacing: normal;
						color: $links-main-color;
						margin-left: 10px;
						margin-right: 10px;
					}

					.text {
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 12px;
						letter-spacing: normal;
						color: $text-main-color;

						.arrowButton {
							color: $links-main-color;
						}
					}	
				}
				
				.rowWithOutColor {
					.entityName {
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 12px;
						letter-spacing: normal;
						color: $links-main-color;
						margin-left: 10px;
						margin-right: 10px;
					}

					.text {
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 12px;
						letter-spacing: normal;
						color: $text-main-color;
					}

					.arrowButton {
						color: $links-main-color;
					}
				}
			}

			.text {
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: 0.5px;
				color: $text-main-color;
			}
		}
	}
}
