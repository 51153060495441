@use '../../themes/colors.scss';
@import '../../index.scss';

%navigation-base {
	height: 32px;
	margin: 0 5px;
	background-color: white;
	border-radius: 4px;
	border: solid 1px #ececfa;
}

.icons-pagination.MuiIconButton-root {
	width: 32px;
	@extend %navigation-base;
}

.offset-pagination-input.MuiOutlinedInput-root {
	width: 46px;
	@extend %navigation-base;

	.MuiOutlinedInput-inputMarginDense {
		font-size: 14px;
		text-align: center;
		color: $text-main-color;
	}
}
