@import '../../themes/palette.scss';
@import '../../themes/colors.scss';

.accessControl {
	flex: 1;

	.leftContainer {
		margin: 0 14px 21px 30px;

		.table {
			margin-top: 21px;
		}
	}

	.rightContainer {
		margin: 21px 30px 21px 14px;

		.table {
			margin-top: 21px;
		}
	}
}