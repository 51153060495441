@import "../../../themes/palette.scss";
@import "../../../themes/colors.scss";

.resultsBoxes {
	.box {
		background-color: $white;
		border: solid 1px $property-box-divider;
		flex-direction: column;
		padding: 15px 20px;
		position: relative;

		.top {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 14px;

			.groupLeft {
				display: flex;
				flex-direction: row;

				.title {
					align-items: center;
					color: $text-main-color;
					display: flex;
					font-size: 12px;
					font-stretch: normal;
					font-style: normal;
					font-weight: bold;
					letter-spacing: 0.5px;
					line-height: normal;
				}
			}

			.title {
				align-items: center;
				color: $text-main-color;
				display: flex;
				font-size: 12px;
				font-stretch: normal;
				font-style: normal;
				font-weight: bold;
				letter-spacing: 0.5px;
				line-height: normal;
			}

			.select {
				color: $text-main-color;
				font-size: 12px;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: 0.5px;
				line-height: normal;
			}
		}

		.limit {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.text {
				flex: 2;
			}
		}

		.sortBy {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.select {
				flex: 1;
				margin-right: 5px;
			}
		}

		.unique {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			.label {
				color: $text-main-color;
				font-size: 12px;
				font-stretch: normal;
				font-style: normal;
				font-weight: normal;
				letter-spacing: normal;
				line-height: normal;
			}
		}

		.list {
			display: flex;

			.checkbox {
				display: flex;
				flex-direction: column;
				flex: 1;
			}
		}
	}
}
