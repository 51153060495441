@import "../../../themes/palette.scss";
@import "../../../themes/colors.scss";

.propertyNumberBox {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.selected {
		flex: 1;
		margin-right: 5px;
	}

	.text {
		flex: 2;
	}
}
