@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.videoSearchFilter {
	.container {
		box-shadow: 0 1px 6px 0 $app-bar-shadow;

		.top {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 20px;

			.button {
				width: 140px;
			}
		}
	}

	.properties {
		padding: 20px;

		.title {
			color: $text-main-color;
			font-size: 15px;
			font-stretch: normal;
			font-style: normal;
			font-weight: bold;
			letter-spacing: 0.38px;
			line-height: normal;
			margin-bottom: 15px;
		}

		.boxes {
			> :first-child {
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
			}

			> :not(:first-child) {
				border-top-width: 0;
			}

			> :last-child {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}
		}
	}

	.addFilter {
		color: $links-main-color;
		font-size: 12px;
		font-stretch: normal;
		font-style: normal;
		font-weight: normal;
		letter-spacing: 0.43px;
		line-height: normal;
		margin-bottom: 25px;
		margin-left: 25px;

		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
}
