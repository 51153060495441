@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.propertyBox {
	background-color: $white;
	border: solid 1px $property-box-divider;
	flex-direction: column;
	padding: 15px 20px;
	position: relative;

	.delete {
		color: $links-main-color;
		font-size: 12px;
		position: absolute;
		right: 0px;
		top: 0px;
	}

	.top {
		align-items: center;
		display: flex;
		margin-bottom: 14px;

		.title {
			color: $text-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: bold;
			letter-spacing: 0.5px;
			line-height: normal;
		}
	}
}
