@import '../../themes/palette.scss';
@import '../../themes/colors.scss';

.appBar {
	background-color: $white;
	align-items: center;
	justify-content: center;
	box-shadow: 0 1px 6px 0 $app-bar-shadow;
	height: 70px;

	.appBarTitleContainer {
		margin-right: 2%;
		width: 170px;
		align-items: center;
		justify-content: center;
		height: 100%;
		display: flex;

		.appBarTitle {
			width: 100%;
		}
	}

	.app-bar__divider {
		width: 1px;
		height: 30px;
		border-radius: 0.5px;
		background-color: $divider-color;
	}

	.navButtons {
		height: 100%;
		flex-grow: 1;
		margin-left: 1%;

		.appBarTabs {
			height: 100%;
		}

		.flexContainer {
				height: 100%;
			}
		

		.tabsIndicator {
			background-color: $links-main-color;
		}

		.appBarTab {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.5px;
			color: $text-main-color;
		}
	}
	
	.appBarUser {
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		font-size: 12px;
		font-stretch: normal;
		font-style: normal;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.33px;
		line-height: 14px;
		color: $top-bar-user-color;
	}
}
