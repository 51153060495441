@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

$white-background-color: #FFFFFF;

.platformButton {
	background-color: $links-main-color !important;
	color: $white !important;
	font-family: Roboto;
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: bold;
	letter-spacing: 0.43px;
	line-height: normal;
	text-align: center;
	text-transform: unset !important;
	width: 100% !important;

	&.disabledButton {
		background-color: $text-secondary-color !important;
		color: $white !important;
	}

	&.maxContentWith {
		width: max-content !important;
	}

	&.outlinedButton {
		background-color: $white-background-color !important;
		border-radius: 4px;
		border: 1px solid $auth-button-color;
		box-sizing: border-box;
		color: $auth-button-color !important;
		font-size: 0.875rem;
		font-weight: normal !important;
		letter-spacing: 1.25px;
		line-height: 1rem;
		text-align: center;
	}

	&.textButton {
		background-color: $white-background-color !important;
		color: $auth-button-color !important;
		font-size: 0.75rem;
		font-size: 0.875rem;
		letter-spacing: 0.33px;
	}
}

.secondaryButton {
	background-color: darken($background-color, 5%) !important;
	color: $links-main-color !important;
	font-weight: bold !important;
}

.tertiaryButton{
	background-color: red !important;
	font-weight: bold !important;
}

.controlAccessButton {
	background-color: $auth-button-background !important;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
	color: $auth-button-color !important;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.33px;
	line-height: 16px;
	margin-top: 10px !important;
}
