@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.connectionsTable {
	box-shadow: 0 1px 6px 0 $app-bar-shadow;

	.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px;
	}

	.header {
		background-color: darken($background-color, 5%);

		.text {
			color: $text-main-color;
			font-size: 10px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: 0.5px;
			line-height: normal;
		}
	}

	.rowWithColor {
		background-color: $background-color;

		.entityName {
			color: $links-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: 2.83;
			margin-left: 10px;
		}

		.textBody {
			color: $text-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: 2.83;
		}

		.textHeader {
			color: $text-secondary-color;
			font-size: 10px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: 0.83px;
			line-height: 2.83;
		}

		.arrowButton {
			color: $links-main-color;
		}

		.showBordersAndText {
			color: $text-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: 2.83;
			border-left: solid 1px $divider-color;
			border-right: solid 1px $divider-color;
		}

		.index {
			color: $yellow;
			font-size: 8px;
		}
	}

	.rowWithoutColor {
		.entityName {
			color: $links-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: 2.83;
			margin-left: 10px;
		}

		.textBody {
			color: $text-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: 2.83;
		}

		.textHeader {
			color: $text-secondary-color;
			font-size: 10px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: 0.83px;
			line-height: 2.83;
		}

		.arrowButton {
			color: $links-main-color;
		}

		.showBordersAndText {
			color: $text-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: 2.83;
			border-left: solid 1px $divider-color;
			border-right: solid 1px $divider-color;
		}

		.index {
			color: $yellow;
			font-size: 8px;
		}
	}

	.textEmptyDB {
		color: $text-main-color;
		font-size: 10px;
		font-stretch: normal;
		font-style: normal;
		font-weight: normal;
		letter-spacing: 0.5px;
		line-height: normal;
	}
}
