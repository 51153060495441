@import "../../../themes/colors.scss";
@import "../../../themes/palette.scss";

.querySaveList {
	height: 93%;
	background: white;
	overflow: hidden;
	overflow-y: auto;

	.emptyHistoryQueries{
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		height: 80%;
		text-align: center;
		color: #CACACA;
	}

	.saveQueryCard {
		height: auto;
		border-bottom: 1px solid #eeee;
		padding: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.nameQueryCard {
			font-size: 14px;
			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
			margin-bottom: 7px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			cursor: pointer;
		}

		.bottomQueryCard {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
			font-size: 12px;
			color: #AAA;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;

			>.actions {
				.deleteItem {
					top: 0px;
					right: 0px;
					font-size: 5px;
					width: 20px;
					height: 20px;
					color: $links-main-color;
				}

				.saveItem {
					top: 0px;
					right: 0px;
					font-size: 5px;
					width: 20px;
					height: 20px;

					&.active {
						color: $links-main-color;
					}
				}
			}
		}
	}

	.saveQueryCard:hover {
		background-color: #fafafa;
		cursor: pointer;
	}

	.saveQueryCardSelected {
		background-color: #E8E8E8;
	}
}
