$dark-purple: #2b2c7d;
$dark-purple-accent: #555697;

$purple: #4748ad;
$purple-accent: #6b6cbd;

$dark-blue: #015bc6;
$dark-blue-accent: #337bd1;

$blue: #017ec6;
$blue-accent: #3397d1;

$light-blue: #39acef;
$light-blue-accent: #60bcf2;

$light-green: #1dd7ce;
$light-green-accent: #4adfd7;

$green: #05b7af;
$green-accent: #37c5bf;

$yellow: #ffa60e;
$yellow-accent: #ffb73e;

$orange: #ff810e;
$orange-accent: #ff9a3e;

$salmon: #eb5f47;
$salmon-accent: #ef7f6b;

$red: #c22f16;
$red-accent: #ce5844;

$magenta: #b50e77;
$magenta-accent: #c33e92;

$white: #ffffff;
