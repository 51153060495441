@import "../../themes/colors.scss";
@import "../../themes/palette.scss";

.users-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  &__title-container {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 66%;

    &__search-container {
      flex: 1;
    }

    &__add-user-button-container {
      width: 150px;
      margin-left: 20px;
    }
  }
}

.access-control-table-container {
  box-shadow: 0 1px 6px 0 $app-bar-shadow;

  .access-control-table {
    box-shadow: 0 1px 6px 0 $app-bar-shadow;

    .access-control-table__header {
      background-color: darken($background-color, 5%);

      .access-control-table__text-header {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: $text-main-color;

        &:first-child {
          padding-left: 24px;
        }
      }
    }

    .MuiTableCell-root {
      border-color: $divider-color;
      border-bottom-width: 1px;
    }

    .access-control-table__text-body {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 12px;
      letter-spacing: normal;
      color: $text-main-color;

      &.header {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 12px;
        letter-spacing: 0.83px;
        color: $text-secondary-color;
      }

      &.show-borders {
        border-right: solid 1px $divider-color;
        border-left: solid 1px $divider-color;
      }

      .access-control-table__index {
        color: $yellow;
        font-size: 8px;
      }
    }

    .access-control-table__row {
      background-color: $background-color;
    }

    .access-control-table__arrow-button {
      color: $links-main-color;
    }

    .access-control-table__entity-name {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 12px;
      letter-spacing: normal;
      color: $links-main-color;
      margin-left: 10px;
      margin-right: 10px;
    }

    .access-control-table__top {
      padding: 20px;
      flex-direction: row;
      justify-content: space-between;
      display: flex;
    }
  }
}
