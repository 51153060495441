@import "../../index.scss";

.container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	max-width: 100%;
	max-height: 100%;

	.content {
		width: 80vh;
		max-height: 100%;
	}
}
