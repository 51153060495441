@use "../../themes/colors.scss";
@import "../../index.scss";

.videoProperties {
	display: flex;
	flex-direction: column;
	height: 100%;

	.titleLeft {
		@extend .text-column-title;
		background-color: $modal-properties-title;
		mix-blend-mode: multiply;
		padding: 2px 0 2px 10px;
		margin-top: 0;
		box-shadow: 0 -1px 0 0 $divider-color;
		height: 1vw;
	}

	.container {
		flex: 1;
		overflow: auto;
		margin-right: -15px;
		padding-right: 15px;

		.property {
			@extend .text-regular;

			.propertyName {
				@extend .text-regular;
				display: inline-block;
				word-wrap: break-word;
				width: 5vw;
				font-weight: bold;
				position: relative;
				vertical-align: top;
			}

			.propertyValue {
				@extend .text-regular;
				display: inline-block;
				word-wrap: break-word;
				width: 60%;
				margin-left: 10%;
			}
		}
	}
}
