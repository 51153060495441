@import '../../themes/palette.scss';
@import "../../index.scss";

.home {
	display: flex;
	flex-direction: column;
	height: 100%;

	.container {
		position: relative;
		height: 100%;
	}
}
