@import "../../themes/palette.scss";
@import "../../index.scss";

.advancedSearch {
	flex: 1;
	visibility: "visible";

	.container {
		.top {
			margin-top: 20px;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			height: 100%;

			.actions {
				flex: 1;
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.searchContainer {
					margin-left: 20px;
					width: 80%;
				}

				.typeSelectContainer {
					margin-left: 10px;
					margin-right: 10px;
					width: 200px;
				}

				.searchButtonContainer {
					margin-right: 20px;
					width: 150px;
				}
			}
		}

		.contentView {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			height: 100%;
			justify-content: center;
			margin: 25px 0px;

			.image {
				height: 80%;
			}
		}
	}
}

.advancedSearchHidden {
	display: none;
	visibility: "hidden";
}
