@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.Resizer {
	background: darken($divider-color, 5%);
	opacity: 0.2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;

	&:hover {
		-webkit-transition: all 2s ease;
		transition: all 2s ease;
	}

	&.vertical {
		width: 16px;
		margin: 0 -5px;
		border-left: 5px solid rgba(255, 255, 255, 0);
		border-right: 5px solid rgba(255, 255, 255, 0);
		cursor: col-resize;

		&:hover {
			border-left: 5px solid rgba(0, 0, 0, 0.5);
			border-right: 5px solid rgba(0, 0, 0, 0.5);
		}
	}

	&.horizontal {
		height: 16px;
		margin: -5px 0;
		border-top: 5px solid rgba(255, 255, 255, 0);
		border-bottom: 5px solid rgba(255, 255, 255, 0);
		cursor: row-resize;
		// width: 100%;

		&:hover {
			border-top: 5px solid rgba(0, 0, 0, 0.5);
			border-bottom: 5px solid rgba(0, 0, 0, 0.5);
		}
	}

	&.disabled {
		cursor: not-allowed;

		&:hover {
			border-color: transparent;
		}
	}
}

.Pane {
	&.vertical {
		overflow-y: auto;
	}

	&.horizontal {
		overflow-x: auto;
	}
}
