@import "../../../themes/palette.scss";
@import "../../../themes/colors.scss";

.propertyDateBox {
	display: flex;
	flex-direction: column;

	.inputs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.selected {
			flex: 1;
			margin-right: 5px;
		}

		.text {
			flex: 2;
		}
	}

	.advanced {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 6px;

		.link {
			color: $links-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: 0.43px;
			line-height: normal;

			&:hover {
				cursor: pointer;
				text-decoration: none;
			}
		}
	}
}
