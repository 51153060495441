@import "./themes/colors.scss";

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  height: 100%;
}

h1,
h2,
h3 {
  font-family: "Roboto";
  font-weight: "bold";
}

h1 {
  font-size: 22px;
  letter-spacing: 0.55px;
}

h2 {
  font-size: 15px;
  letter-spacing: 0.38px;
}

.h3 {
  font-size: 12px;
  letter-spacing: "normal";
}

.text-base {
  color: $text-main-color;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}

.text-menu-item-selected {
  @extend .text-base;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.text-menu-item {
  @extend .text-base;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

.text-column-title {
  @extend .text-base;
  text-transform: uppercase;
  font-size: clamp(6.95px, 0.73vw, 14.06px);
  font-weight: normal;
  letter-spacing: 0.5px;
}

.text-regular {
  @extend .text-base;
  font-size: clamp(6.95px, 0.73vw, 14.06px);
  font-weight: normal;
  letter-spacing: normal;
}

.links {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.43px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: $links-main-color;
}

.CircularProgressbar-path {
	stroke: $links-main-color !important;
}

.Mui-selected{
  font-weight: bold !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-button {
  .MuiButton-startIcon {
    animation: rotation 2s infinite linear !important;
  }
}

.MuiButton-containedPrimary {
  background-color: $links-main-color !important;
}

#root {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scroll-background;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scroll-hover-background;
}

.withoutBorder {
  .MuiTableCell-root {
    border-color: $divider-color;
    border-bottom-width: 0;
  }
}