// Constants 
$dark-color: #000;
$bar-standard-color: #E6E6FA;
$bar-selected-color: #5c3f78;
$tooltip-background-color: white;
$tooltip-span-color: #d11515;
$tooltip-header:	#808080;

#barChart {
	overflow-y: auto;  
	
	.bar { 
		fill: $bar-standard-color;
	}
	
	.bar:hover {
		fill: $bar-selected-color;
	}
	
	.tooltip {
		background: $tooltip-background-color;
		border: 0;
		border-radius: 8px;
		font-weight: bold;
		padding: 2px;
		padding-top: 7px;
		pointer-events: none;
		position: absolute;
		text-align: center;
		width: 120px;
		height: 60px;
		visibility: hidden;

		.tooltip-header {
			color: $tooltip-header;
			font-size: 12px;
		}

		.tooltip-body {
			color: $dark-color;
			font-size: 18px;
			text-align: center;
		}	
	}
	
}

