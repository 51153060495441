@import "../../../themes/palette.scss";
@import "../../../themes/colors.scss";

.operationThresholdBox {
	display: flex;
	flex-direction: column;

	.value {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex: 1;
	}

	.numbers {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex: 1;
		justify-content: space-between;

		.text {
			color: $text-main-color;
			font-size: 12px;
			font-stretch: normal;
			font-style: normal;
			font-weight: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: right;
		}
	}
}
