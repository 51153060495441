@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

.platformTextField {
	width: 100%;
	background-color: $white;
	height: 30px;

	input {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		padding: 8px 10px;
		color: $text-main-color;
	}

	fieldset {
		border-color: $property-box-divider !important;
	}
}

.platformTextFieldLabel {
	color: $text-main-color !important;
	font-family: Roboto !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	letter-spacing: 0.11px !important;
	line-height: 24px !important;
}

.platformTextFieldControl {
	font-family: Roboto !important;
	font-size: 12px !important;
	background-color: $white;
	width: 100%;

	input {
		font-family: Roboto !important;
		font-size: 12px !important;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: 0.11px;
		line-height: 24px;
		padding: 7px 12px !important;
		color: $text-main-color !important;
	}
}

.platformTextFieldButton {
	padding: 7px 3px !important;
}

