@import '../../themes/colors.scss';
@import '../../themes/palette.scss';

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.iconContainer {
  color: $links-main-color  !important;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  &.platformRefreshButton {
    &:hover {
      cursor: pointer;
    }
  }

  &.rotateRefreshButton {
    animation: rotation 2s infinite linear !important;
  }
}