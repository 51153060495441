@import "../../index.scss";

.image {
	padding: 0 5px;
	margin-bottom: 20px;

	&:hover {
		cursor: pointer;
	}

	.container {
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 192px;
		height: 130px;

		.contentElement {
			max-width: 100%;
			max-height: 100%;
		}
	}

	.description {
		margin: 0;
		padding: 0;

		.titleDescription {
			@extend .text-regular;
			margin: 5px 0;
			font-weight: bold;
		}

		.propertiesDescription {
			@extend .text-regular;
			font-size: 10px;
			margin: 5px 0;
		}
	}
}
